import { UserId } from "@sp-crm/core";
import { Select } from "components/ui/select";
import { GetTenantQuery } from "generated/graphql";
import React, { useEffect } from "react";

interface WorkflowDefaultActorProps {
    tenant: GetTenantQuery["getTenant"];
    value: UserId | null;
    onChange: (value: UserId) => void;
}

export const WorkflowDefaultActor: React.FC<WorkflowDefaultActorProps> = props => {
    const { tenant, value, onChange } = props;

    useEffect(() => {
        if (!value && tenant.users.length) {
            let current = {
                userId: tenant.users[0].id,
                adminCount: 0,
            };

            tenant.users
                .filter(u => !u.disabled && !u.hidden)
                .forEach(user => {
                    const adminCount = tenant.regions.filter(region =>
                        region.permissions.some(
                            permission =>
                                permission.user.id === user.id &&
                                permission.role === "administrator",
                        ),
                    ).length;

                    if (adminCount > current.adminCount) {
                        current = {
                            userId: user.id,
                            adminCount,
                        };
                    }
                });

            onChange(current.userId);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Select
                label="Default actor"
                value={value}
                onChange={e => {
                    if (e.target.value) {
                        onChange(e.target.value as UserId);
                    }
                }}>
                {tenant.users.map(user => (
                    <option key={user.id} value={user.id}>
                        {user.name || user.email || user.id}
                    </option>
                ))}
            </Select>
            <p className="text-sm">
                Workflows will use the most relevant user to evaluate their triggers and
                do their action (e.g. client assigned to, sent message sender). In some
                cases, there is no user to pick (e.g. a client without an assignee), so
                this is the fallback user that will be used in those cases.
            </p>
        </div>
    );
};
