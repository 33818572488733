import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import { Radio } from "components/ui/radio";
import {
    WorkflowActionEmailEntityInput,
    WorkflowActionTaskEntityInput,
    WorkflowPayload,
} from "generated/graphql";
import React from "react";

interface WorkflowActionEditProps {
    action: WorkflowPayload["actionEntities"][0];
    onChange: (action: WorkflowPayload["actionEntities"][0]) => void;
}

export const WorkflowActionTokenHelp: React.FC<unknown> = _ => {
    return (
        <div className="text-sm bg-brand-100 space-y-2 p-4">
            <p>These client tokens are available in the action fields.</p>
            <ul>
                <li>
                    <pre>{`{{client.id}}`}</pre>
                </li>
                <li>
                    <pre>{`{{client.name}}`}</pre>
                </li>
                <li>
                    <pre>{`{{client.status}}`}</pre>
                </li>
                <li>
                    <pre>{`{{client.moveTimelineActual}}`}</pre>
                </li>
            </ul>
            <p>
                To use settings, use <pre className="inline">{`{{settings.thekey}}`}</pre>{" "}
                where {`"thekey"`} is the key of the setting you create. If you use an
                email template ID setting in an email action subject or body, the workflow
                will use the template subject or body.
            </p>
        </div>
    );
};

export const WorkflowActionEdit: React.FC<WorkflowActionEditProps> = props => {
    const { action, onChange } = props;
    let selectedActionType = "";
    if (action.task) {
        selectedActionType = "task";
    } else if (action.email) {
        selectedActionType = "email";
    }
    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === "task") {
            onChange({
                task: { dueDate: "", text: "" },
            });
        } else if (value === "email") {
            onChange({ email: { body: "", subject: "", to: "" } });
        }
    };
    return (
        <div className="space-y-4 p-4 rounded-lg bg-gray-100">
            <div>
                <Radio
                    label="Create task"
                    value="task"
                    checked={selectedActionType === "task"}
                    onChange={handleRadioChange}
                />
                <Radio
                    label="Send email"
                    value="email"
                    checked={selectedActionType === "email"}
                    onChange={handleRadioChange}
                />
            </div>
            {selectedActionType === "task" ? (
                <WorkflowActionTaskEdit
                    action={action.task}
                    onChange={f => onChange({ task: f })}
                />
            ) : null}
            {selectedActionType === "email" ? (
                <WorkflowActionEmailEdit
                    action={action.email}
                    onChange={s => onChange({ email: s })}
                />
            ) : null}
        </div>
    );
};

interface WorkflowActionTaskEditProps {
    action: WorkflowActionTaskEntityInput;
    onChange: (action: WorkflowActionTaskEntityInput) => void;
}

export const WorkflowActionTaskEdit: React.FC<WorkflowActionTaskEditProps> = props => {
    const { action, onChange } = props;
    return (
        <div className="space-y-2">
            <Input
                type="text"
                label={
                    <p>
                        Due Date{" "}
                        <span className="text-xs italic">
                            Use a relative date like +7d for 7 days from when this
                            workflow triggered
                        </span>
                    </p>
                }
                value={action.dueDate}
                onChange={e => onChange({ ...action, dueDate: e.target.value })}
            />
            <Input
                type="text"
                label={
                    <p>
                        Task name <span className="text-xs italic">string</span>
                    </p>
                }
                value={action.text}
                onChange={e => onChange({ ...action, text: e.target.value })}
            />
            <Input
                type="text"
                label={
                    <p>
                        Assigned to{" "}
                        <span className="text-xs italic">{`Use a setting of type "user" or leave blank to use the client Assigned To user`}</span>
                    </p>
                }
                value={action.assignedTo}
                onChange={e => onChange({ ...action, assignedTo: e.target.value })}
            />
            <Input
                type="text"
                label={
                    <p>
                        Task Type Id{" "}
                        <span className="text-xs italic">{`Should be a uuid of one of the task types in the tenant`}</span>
                    </p>
                }
                value={action.taskTypeId}
                onChange={e => onChange({ ...action, taskTypeId: e.target.value })}
            />
            <Checkbox
                checked={!!action.notifyOwner}
                onChange={e => onChange({ ...action, notifyOwner: e.target.checked })}
                label="Notify Owner"
            />
        </div>
    );
};

interface WorkflowActionStatusEditProps {
    action: WorkflowActionEmailEntityInput;
    onChange: (action: WorkflowActionEmailEntityInput) => void;
}

export const WorkflowActionEmailEdit: React.FC<WorkflowActionStatusEditProps> = props => {
    const { action, onChange } = props;
    return (
        <div className="space-y-2">
            <Input
                type="text"
                label="To"
                value={action.to}
                onChange={e => onChange({ ...action, to: e.target.value })}
            />
            <Input
                type="text"
                label="Subject"
                value={action.subject}
                onChange={e => onChange({ ...action, subject: e.target.value })}
            />
            <Input
                type="text"
                label="Body"
                value={action.body}
                onChange={e => onChange({ ...action, body: e.target.value })}
            />
            <Input
                type="text"
                label="Attachments"
                value={action.attachments}
                onChange={e => onChange({ ...action, attachments: e.target.value })}
            />
            <div>
                <Input
                    type="text"
                    label="BCC Myself"
                    value={action.bccMyself}
                    onChange={e => onChange({ ...action, bccMyself: e.target.value })}
                />
                <p className="text-sm">
                    Must be <code>TRUE</code>, <code>FALSE</code>, or a setting.
                </p>
            </div>
        </div>
    );
};
