import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { SearchInput } from "components/ui/search-input";
import { useGetSavedQueriesQuery } from "generated/graphql";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { CustomReportsRouteParams } from "./custom-reports-helpers";

export const linkStyle =
    "text-base block -mx-2 px-2 py-2 text-brand-600 hover:bg-gray-200 hover:text-brand-700 rounded twoverride";

export const activeLinkStyle =
    "text-base block -mx-2 px-2 py-2 text-brand-500 bg-gray-50 hover:bg-gray-200 hover:text-brand-700 rounded twoverride";

export const CustomReportsSidebar: React.FC = () => {
    const query = useGetSavedQueriesQuery();
    const { params } = useRouteMatch<CustomReportsRouteParams>();
    const reportId = params.reportId;
    const isNew = !reportId || reportId === "new";

    const [searchText, setSearchText] = React.useState("");

    return (
        <div className="md:w-36 lg:w-48 ml-4">
            <div className="mt-4">
                <Link
                    to="/reports/show/custom/new"
                    className={isNew ? activeLinkStyle : linkStyle}>
                    <span className="flex items-center space-x-1">
                        <PlusCircleIcon className="h-4 w-4" />
                        <div>New query</div>
                    </span>
                </Link>
            </div>
            <QueryRenderer name="sidebar" query={query}>
                {data => {
                    const favoriteQueries = data.getSavedQueries.savedQueries.filter(
                        q => q.isFavorite,
                    );
                    const myQueries = data.getSavedQueries.savedQueries.filter(
                        q => !q.isShared,
                    );
                    const sharedQueries = data.getSavedQueries.savedQueries.filter(
                        q => q.isShared,
                    );
                    return (
                        <ul>
                            {myQueries.length > 0 || sharedQueries.length > 0 ? (
                                <li>
                                    <SearchInput
                                        value={searchText}
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </li>
                            ) : null}
                            {favoriteQueries.length > 0 ? (
                                <li className="pt-6 uppercase text-gray-600 tracking-wider text-xs mb-1">
                                    Favorites
                                </li>
                            ) : null}
                            {favoriteQueries
                                .filter(d =>
                                    d.name
                                        ?.toLocaleLowerCase()
                                        .includes(searchText.trim().toLocaleLowerCase()),
                                )
                                .map(d => (
                                    <li key={d.id}>
                                        <Link
                                            to={`/reports/show/custom/${d.id}`}
                                            className={
                                                d.id === reportId
                                                    ? activeLinkStyle
                                                    : linkStyle
                                            }>
                                            {d.name ?? "(Untitled)"}
                                        </Link>
                                    </li>
                                ))}
                            {myQueries.length > 0 ? (
                                <li className="pt-6 uppercase text-gray-600 tracking-wider text-xs mb-1">
                                    My Queries
                                </li>
                            ) : null}
                            {myQueries
                                .filter(d =>
                                    d.name
                                        ?.toLocaleLowerCase()
                                        .includes(searchText.trim().toLocaleLowerCase()),
                                )
                                .map(d => (
                                    <li key={d.id}>
                                        <Link
                                            to={`/reports/show/custom/${d.id}`}
                                            className={
                                                d.id === reportId
                                                    ? activeLinkStyle
                                                    : linkStyle
                                            }>
                                            {d.name ?? "(Untitled)"}
                                        </Link>
                                    </li>
                                ))}
                            {sharedQueries.length > 0 ? (
                                <li className="pt-6 uppercase text-gray-600 tracking-wider text-xs mb-1">
                                    Shared Queries
                                </li>
                            ) : null}
                            {sharedQueries
                                .filter(d =>
                                    d.name
                                        ?.toLocaleLowerCase()
                                        .includes(searchText.trim().toLocaleLowerCase()),
                                )
                                .map(d => (
                                    <li key={d.id}>
                                        <Link
                                            to={`/reports/show/custom/${d.id}`}
                                            className={
                                                d.id === reportId
                                                    ? activeLinkStyle
                                                    : linkStyle
                                            }>
                                            {d.name ?? "(Untitled)"}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    );
                }}
            </QueryRenderer>
        </div>
    );
};
