import * as React from "react";

interface Props {
    label?: string | JSX.Element;
}
export const Radio: React.FC<
    Props & React.InputHTMLAttributes<HTMLInputElement>
> = props => {
    const { label, className, ...rest } = props;
    return (
        <div className={`${className ?? ""} w-full`}>
            <label className="flex items-center">
                <input
                    type="radio"
                    className="form-radio disabled:bg-gray-100 border-gray-400 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                    {...rest}
                />
                {label ? <div className="ml-2">{label}</div> : null}
            </label>
        </div>
    );
};
