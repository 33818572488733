import { RegionId } from "@sp-crm/core";
import axios from "axios";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import React from "react";

interface Props {
    endpoint: string;
    onComplete: () => void;
    onError: (message: string) => void;
    regionId: RegionId;
    fields?: Record<string, string>;
    accept?: string;
}

type State = "ready" | "in-progress" | "error";

export const Upload: React.FunctionComponent<Props> = props => {
    const { endpoint, onComplete, onError, fields, accept, regionId } = props;
    const [state, setState] = React.useState<State>("ready");
    const inputRef = React.useRef<HTMLInputElement>(null);
    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            if (inputRef.current) {
                inputRef.current.click();
            }
        },
        [inputRef],
    );
    const onFileChange = React.useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("regionId", regionId);
            if (fields) {
                Object.keys(fields).forEach(key => {
                    formData.append(key, fields[key]);
                });
            }
            try {
                setState("in-progress");
                await axios.post(endpoint, formData);
                onComplete();
                setState("ready");
                inputRef.current.value = "";
            } catch (e) {
                setState("error");
                onError(e.response?.data?.msg ? e.response.data.msg : e.message);
            }
        },
        [endpoint, fields, onComplete, onError, setState, regionId],
    );
    return (
        <div className="space-y-2">
            <input
                type="file"
                name="file"
                ref={inputRef}
                onChange={onFileChange}
                hidden
                accept={accept}
            />
            <div className="flex space-x-1">
                <SecondaryButton onClick={handleClick} disabled={state === "in-progress"}>
                    Upload file
                </SecondaryButton>
                {state === "in-progress" ? <Spinner /> : null}
            </div>
        </div>
    );
};
