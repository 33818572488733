import { Switch, SwitchGroup } from "@headlessui/react";
import React from "react";

interface ToggleProps {
    label: string | null;
    onChange: (isChecked: boolean) => void;
    checked: boolean;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export const Toggle: React.FC<ToggleProps> = props => {
    const { label, onChange, checked } = props;
    return (
        <SwitchGroup as="div" className="flex items-center">
            <Switch
                checked={checked}
                onChange={onChange}
                className={classNames(
                    checked ? "bg-brand-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-300 focus:ring-offset-1",
                )}>
                <span
                    aria-hidden="true"
                    className={classNames(
                        checked ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                />
            </Switch>
            {label ? (
                <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-900">{label}</span>
                </Switch.Label>
            ) : null}
        </SwitchGroup>
    );
};
