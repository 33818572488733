import { genUuid } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import * as React from "react";
import { ProductBranding } from "store/selectors/branding";
import { reportError } from "../../util/error-tracking";
import { Content, SectionHeader, Stage } from "../layout";

interface State {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    error: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    errorInfo: any;
    errorId: string | null;
}

interface Props {
    children: React.ReactNode;
    branding: ProductBranding;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null, errorInfo: null, errorId: null };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    componentDidCatch(error: any, errorInfo: any) {
        const errorId = genUuid().toString();
        this.setState({
            error: error,
            errorInfo: errorInfo,
            errorId,
        });
        const skipReporting = !!error?.skipReporting;

        if (!skipReporting) {
            reportError(error, { errorId });
        }
    }

    render() {
        if (this.state.error?.errorType === "NOT_FOUND") {
            return (
                <Stage>
                    <SectionHeader title="Record not found" />
                    <Content>
                        {`Sorry, the record you are accessing does not exist (or it was
                        deleted). Try clicking "back" or close this tab.`}
                    </Content>
                </Stage>
            );
        } else if (this.state.errorInfo) {
            // Error path
            return (
                <Stage>
                    <SectionHeader title="Sorry! Something went wrong." />
                    <Content>
                        <div className="flex space-x-2 lg:space-x-4">
                            <div className="w-16 lg:w-32">
                                <svg
                                    className="text-yellow-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div>
                                <h3 className="font-semibold text-lg">
                                    Sorry to interrupt what you were doing!
                                </h3>
                                {this.props.branding ? (
                                    <p>
                                        Please try reloading your browser and try again.
                                        If that doesn&apos;t fix the problem, please email{" "}
                                        <SupportEmailLink
                                            subject={`${this.props.branding.productName} error ${this.state.errorId}`}
                                        />{" "}
                                        and include{" "}
                                        <strong>
                                            <code>Error ID {this.state.errorId}</code>
                                        </strong>
                                        .
                                    </p>
                                ) : (
                                    <p>
                                        Please try reloading your browser and try again.{" "}
                                        <strong>
                                            <code>Error ID {this.state.errorId}</code>
                                        </strong>
                                    </p>
                                )}
                                <details style={{ whiteSpace: "pre-wrap" }}>
                                    {this.state.error && this.state.error.toString()}
                                    <br />
                                    {this.state.errorInfo.componentStack}
                                </details>
                            </div>
                        </div>
                    </Content>
                </Stage>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
