import { PlusIcon } from "@heroicons/react/20/solid";
import { Input } from "components/ui/input";
import { defaultLinkStyle } from "components/ui/link";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    GetTenantQuery,
    GetWorkflowsForTenantQuery,
    WorkflowDefaultsFragment,
    WorkflowPayload,
    WorkflowSettingType,
    useUpdateWorkflowForTenantMutation,
} from "generated/graphql";
import React, { useCallback, useState } from "react";
import { replaceItemAtIndex } from "./util";
import { WorkflowActionEdit, WorkflowActionTokenHelp } from "./workflow-action";
import { WorkflowDefaultActor } from "./workflow-default-actor";
import { WorkflowSettingEdit } from "./workflow-setting";
import { WorkflowStatusEdit } from "./workflow-status";
import { WorkflowTriggerEdit } from "./workflow-trigger";

interface WorkflowEditProps {
    tenant: GetTenantQuery["getTenant"];
    workflow: GetWorkflowsForTenantQuery["getWorkflowsForTenant"][0];
    cancelEdit: () => void;
}

const emptyAction: WorkflowPayload["actionEntities"][0] = {};

const emptySetting: WorkflowPayload["settings"][0] = {
    defaultValue: "",
    description: "",
    key: "",
    type: WorkflowSettingType.String,
    label: "",
    scope: "tenant",
};

export const workflowPayloadFromWorkflow = (
    workflow: WorkflowDefaultsFragment,
): WorkflowPayload => {
    const triggerInput: WorkflowPayload["triggerInput"] = {
        eventType: workflow.trigger.eventType,
        entityUpdated: workflow.trigger.entityUpdated,
        noteAdded: workflow.trigger.noteAdded,
        emailOpened: workflow.trigger.emailOpened,
        schedule: workflow.trigger.schedule,
    };
    const actionEntities: WorkflowPayload["actionEntities"] = [];
    for (const action of workflow.actions) {
        if (action.__typename === "WorkflowActionEmailEntity") {
            actionEntities.push({
                email: {
                    to: action.to,
                    subject: action.subject,
                    body: action.body,
                    attachments: action.attachments,
                    bccMyself: action.bccMyself,
                },
            });
        }
        if (action.__typename === "WorkflowActionTaskEntity") {
            actionEntities.push({
                task: {
                    text: action.text,
                    dueDate: action.dueDate,
                    assignedTo: action.assignedTo,
                    taskTypeId: action.taskTypeId,
                    notifyOwner: action.notifyOwner,
                    notification: action.notification,
                },
            });
        }
    }
    const result = {
        attachTo: workflow.attachTo,
        name: workflow.name,
        description: workflow.description,
        delay: workflow.delay,
        delayRecipe: workflow.delayRecipe,
        triggerInput,
        actionEntities,
        settings: workflow.settings.map(s => ({
            defaultValue: s.defaultValue,
            description: s.description,
            key: s.key,
            type: s.type,
            label: s.label,
            scope: s.scope,
        })),
        status: workflow.status,
        defaultActorId: workflow.defaultActorId,
        actorMode: workflow.actorMode,
    };
    return result;
};

export const WorkflowEdit: React.FC<WorkflowEditProps> = props => {
    const { tenant, workflow, cancelEdit } = props;
    const tenantId = tenant.id;
    const updateMutation = useUpdateWorkflowForTenantMutation();
    const [editingWorkflow, setWorkflow] = useState<WorkflowPayload>(
        workflowPayloadFromWorkflow(workflow),
    );
    const [isAdvancedEnabled, setShowAdvanced] = useState(false);
    const update = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            await updateMutation.mutateAsync({
                tenantId,
                workflowId: workflow.id,
                workflow: editingWorkflow,
            });
            cancelEdit();
        },
        [workflow, tenantId, cancelEdit, editingWorkflow, updateMutation],
    );
    return (
        <form onSubmit={update} className="space-y-4">
            <div className="space-y-2 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4">
                <div>
                    <WorkflowStatusEdit
                        value={editingWorkflow.status}
                        onChange={s => setWorkflow({ ...editingWorkflow, status: s })}
                    />
                </div>
                <div>
                    <Input
                        type="text"
                        label="Name"
                        value={editingWorkflow.name}
                        onChange={e =>
                            setWorkflow({ ...editingWorkflow, name: e.target.value })
                        }
                    />
                </div>
                <div>
                    <Input
                        type="text"
                        label="Description"
                        value={editingWorkflow.description}
                        onChange={e =>
                            setWorkflow({
                                ...editingWorkflow,
                                description: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        label="Delay (seconds)"
                        value={editingWorkflow.delay}
                        onChange={e =>
                            setWorkflow({
                                ...editingWorkflow,
                                delay: parseInt(e.target.value, 10),
                            })
                        }
                    />
                </div>
                <div>
                    <Input
                        type="text"
                        label="Delay (recipe)"
                        value={editingWorkflow.delayRecipe}
                        onChange={e =>
                            setWorkflow({
                                ...editingWorkflow,
                                delayRecipe: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <WorkflowDefaultActor
                        tenant={tenant}
                        value={editingWorkflow.defaultActorId}
                        onChange={d =>
                            setWorkflow({
                                ...editingWorkflow,
                                defaultActorId: d,
                            })
                        }
                    />
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="text-lg">Trigger</h2>
                <WorkflowTriggerEdit
                    tenantId={tenantId}
                    trigger={editingWorkflow.triggerInput}
                    onChange={t =>
                        setWorkflow({
                            ...editingWorkflow,
                            triggerInput: t,
                        })
                    }
                />
            </div>
            <div className="space-y-2">
                <h2 className="text-lg">Actions</h2>
                <WorkflowActionTokenHelp />
                <div className="space-y-2">
                    {editingWorkflow.actionEntities.map((action, index) => (
                        <WorkflowActionEdit
                            key={index}
                            action={action}
                            onChange={a =>
                                setWorkflow({
                                    ...editingWorkflow,
                                    actionEntities: replaceItemAtIndex(
                                        editingWorkflow.actionEntities,
                                        index,
                                        a,
                                    ),
                                })
                            }
                        />
                    ))}
                    <SecondaryButton
                        onClick={e => {
                            e.preventDefault();
                            setWorkflow({
                                ...editingWorkflow,
                                actionEntities: [
                                    ...editingWorkflow.actionEntities,
                                    emptyAction,
                                ],
                            });
                        }}>
                        <PlusIcon className="w-6 h-6" />
                        <div>Add action</div>
                    </SecondaryButton>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="text-lg">Settings</h2>
                <div className="space-y-2">
                    {editingWorkflow.settings.map((setting, index) => (
                        <WorkflowSettingEdit
                            key={index}
                            setting={setting}
                            onChange={s =>
                                setWorkflow({
                                    ...editingWorkflow,
                                    settings: replaceItemAtIndex(
                                        editingWorkflow.settings,
                                        index,
                                        s,
                                    ),
                                })
                            }
                        />
                    ))}
                    <SecondaryButton
                        onClick={e => {
                            e.preventDefault();
                            setWorkflow({
                                ...editingWorkflow,
                                settings: [...editingWorkflow.settings, emptySetting],
                            });
                        }}>
                        <PlusIcon className="w-6 h-6" />
                        <div>Add setting</div>
                    </SecondaryButton>
                </div>
            </div>
            <div className="space-x-2 items-center">
                <PrimaryButton type="submit">Save</PrimaryButton>
                <SecondaryButton onClick={cancelEdit}>Cancel</SecondaryButton>
            </div>
            <a
                href="#"
                onClick={e => {
                    e.preventDefault();
                    setShowAdvanced(b => !b);
                }}
                className={defaultLinkStyle}>
                Advanced
            </a>
            <pre className={isAdvancedEnabled ? "block" : "hidden"}>
                {JSON.stringify(editingWorkflow, null, 2)}
            </pre>
        </form>
    );
};
