import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Select } from "../ui/select";

interface CrmTablePaginationProps {
    /**
     * 0-based index
     */
    pageIndex: number;
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageCount: number;
    nextPage: () => void;
    previousPage: () => void;
    gotoPage: (pageIndex: number) => void;
    pageSize: number;
    setPageSize?: (pageSize: number) => void;
    count: number;
    allowAll?: boolean;
}

export const CrmTablePagination: React.FC<CrmTablePaginationProps> = props => {
    const {
        gotoPage,
        pageIndex,
        canPreviousPage,
        canNextPage,
        nextPage,
        pageSize,
        previousPage,
        pageCount,
        setPageSize,
        count,
    } = props;
    const beginIndex = pageIndex * pageSize + 1;
    const endIndex = Math.min(beginIndex + pageSize - 1, count);
    const realPageCount = Math.ceil(pageCount);
    const allowAll = props.allowAll ?? true;
    if (realPageCount !== pageCount) {
        console.warn("pageCount is not an integer, but it should be!");
    }
    return (
        <div className="py-3 flex items-center justify-between">
            <div className="text-sm text-gray-700">
                {count === 0 ? (
                    <strong>No results</strong>
                ) : (
                    <span>
                        Showing <strong>{beginIndex}</strong> to{" "}
                        <strong>{endIndex}</strong> of <strong>{count}</strong>
                    </span>
                )}
            </div>
            <div>
                <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination">
                    {canPreviousPage ? (
                        <>
                            <button
                                onClick={() => gotoPage(0)}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">First</span>
                                <ChevronDoubleLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                onClick={() => previousPage()}
                                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-gray-100 text-sm font-medium text-gray-300">
                                <span className="sr-only">First</span>
                                <ChevronDoubleLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-gray-100 text-sm font-medium text-gray-300">
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </>
                    )}
                    {canNextPage ? (
                        <>
                            <button
                                onClick={() => nextPage()}
                                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                onClick={() => gotoPage(realPageCount - 1)}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">Last</span>
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-gray-100 text-sm font-medium text-gray-300">
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-100 text-sm font-medium text-gray-300">
                                <span className="sr-only">Last</span>
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </>
                    )}
                </nav>
            </div>
            <div>
                <label className="flex items-center space-x-2">
                    <div>Per page</div>
                    {setPageSize ? (
                        <Select
                            value={pageSize === 999999 ? "All" : pageSize}
                            onChange={e => {
                                const newPageSize =
                                    e.target.value === "All"
                                        ? 999999
                                        : Number(e.target.value);
                                setPageSize(newPageSize);
                                if (pageIndex * newPageSize >= count) {
                                    gotoPage(Math.floor(count / newPageSize));
                                }
                            }}>
                            {[25, 50, 100, allowAll ? "All" : null]
                                .filter(x => !!x)
                                .map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                        </Select>
                    ) : (
                        <strong>{pageSize}</strong>
                    )}
                </label>
            </div>
        </div>
    );
};
