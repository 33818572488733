import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React from "react";

interface IconProps {
    className?: string;
}

export const SortUp: React.FC<IconProps> = props => (
    <ChevronUpIcon className={`w-3 h-3 ${props.className}`} />
);

export const SortDown: React.FC<IconProps> = props => (
    <ChevronDownIcon className={`w-3 h-3 ${props.className}`} />
);

export const GroupUngrouped: React.FC<IconProps> = props => (
    <svg
        className={`w-4 h-4 ${props.className}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"></path>
    </svg>
);

export const GroupGrouped: React.FC<IconProps> = props => (
    <svg
        className={`w-4 h-4 ${props.className}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"></path>
    </svg>
);

export const ExpandRight: React.FC<IconProps> = props => (
    <svg
        className={`w-4 h-4 ${props.className}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
    </svg>
);

export const ExpandDown: React.FC<IconProps> = props => (
    <svg
        className={`w-4 h-4 ${props.className}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 14l-7 7m0 0l-7-7m7 7V3"
        />
    </svg>
);

export const WarningIcon: React.FC<IconProps> = props => (
    <svg
        className={`w-4 h-4 ${props.className}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
    </svg>
);
