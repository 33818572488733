import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortDirection,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { SortDown, SortUp } from "../ui/icon";
import { CrmTablePagination } from "./pagination";
import { CrmTableColumn } from "./types";

export interface CrmTableProps<T = unknown> {
    data: T[];
    columns: CrmTableColumn<T>[];
    special?: (props: { record: T | undefined }) => JSX.Element;
    initialSort?: SortingState;
}

const SortControl: React.FC<{ isSorted: false | SortDirection }> = props => {
    const { isSorted } = props;
    if (isSorted === false) {
        return <div className="w-3 h-3" />;
    }
    if (isSorted === "desc") {
        return <SortDown />;
    }
    return <SortUp />;
};

export const CrmTable = <T,>(props: CrmTableProps<T>): JSX.Element => {
    const { data, columns, special, initialSort } = props;
    const columnHelper = createColumnHelper<T>();
    const actualColumns = React.useMemo(() => {
        const result = columns.map(c => {
            const key = c.key as keyof T;
            if (c.renderCell) {
                return columnHelper.accessor((row: T) => row[key], {
                    id: key.toString(),
                    header: c.header ?? key.toString(),
                    enableSorting: c.enableSorting ?? true,
                    cell: props =>
                        c.renderCell({ record: props.row.original } as { record: T }),
                });
            }
            return columnHelper.accessor((row: T) => row[key], {
                id: key.toString(),
                header: c.header ?? key.toString(),
                enableSorting: c.enableSorting ?? true,
            });
        });

        if (special) {
            result.push(
                columnHelper.display({
                    id: "_actions",
                    header: "",
                    cell: c => special({ record: c.row.original }),
                }),
            );
        }
        return result;
    }, [columns, columnHelper, special]);
    const [sorting, setSorting] = React.useState<SortingState>(initialSort || []);
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 25,
    });
    const table = useReactTable({
        columns: actualColumns,
        data,
        state: {
            sorting,
            pagination,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });
    return (
        <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="py-3.5 text-left text-sm font-semibold text-gray-900 px-3">
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? "cursor-pointer select-none"
                                                                : "",
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}>
                                                    <div className="flex items-center space-x-1">
                                                        <div>
                                                            {flexRender(
                                                                header.column.columnDef
                                                                    .header,
                                                                header.getContext(),
                                                            )}
                                                        </div>
                                                        <div>
                                                            <SortControl
                                                                isSorted={header.column.getIsSorted()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} className="hover:bg-gray-100">
                                    {row.getVisibleCells().map(cell => (
                                        <td
                                            key={cell.id}
                                            className={`px-3 whitespace-nowrap py-4  text-sm font-medium text-gray-900 `}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>

                        {table.getCanPreviousPage() ||
                        table.getCanNextPage() ||
                        data.length > 25 ? (
                            <tfoot>
                                <tr>
                                    <td colSpan={table.getVisibleFlatColumns().length}>
                                        <CrmTablePagination
                                            pageIndex={
                                                table.getState().pagination.pageIndex
                                            }
                                            canPreviousPage={table.getCanPreviousPage()}
                                            canNextPage={table.getCanNextPage()}
                                            pageCount={table.getPageCount()}
                                            nextPage={() => table.nextPage()}
                                            previousPage={() => table.previousPage()}
                                            gotoPage={table.setPageIndex}
                                            pageSize={
                                                table.getState().pagination.pageSize
                                            }
                                            setPageSize={table.setPageSize}
                                            count={data.length}
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        ) : null}
                    </table>
                </div>
            </div>
        </div>
    );
};
