import { Input } from "components/ui/input";
import { Radio } from "components/ui/radio";
import { WorkflowPayloadSettingsInput, WorkflowSettingType } from "generated/graphql";
import React from "react";

interface WorkflowSettingEditProps {
    setting: WorkflowPayloadSettingsInput;
    onChange: (setting: WorkflowPayloadSettingsInput) => void;
}

export const WorkflowSettingEdit: React.FC<WorkflowSettingEditProps> = props => {
    const { setting, onChange } = props;
    return (
        <div className="space-y-4 p-4 rounded-lg bg-gray-100">
            <Input
                type="text"
                label="defaultValue"
                value={setting.defaultValue}
                onChange={e => onChange({ ...setting, defaultValue: e.target.value })}
            />
            <Input
                type="text"
                label="description"
                value={setting.description}
                onChange={e => onChange({ ...setting, description: e.target.value })}
            />
            <Input
                type="text"
                label="key"
                value={setting.key}
                onChange={e => onChange({ ...setting, key: e.target.value })}
            />
            <Input
                type="text"
                label="label"
                value={setting.label}
                onChange={e => onChange({ ...setting, label: e.target.value })}
            />
            <div>
                <p>Type</p>
                <Radio
                    checked={WorkflowSettingType.Boolean === setting.type}
                    onChange={() =>
                        onChange({ ...setting, type: WorkflowSettingType.Boolean })
                    }
                    label="Boolean"
                />
                <Radio
                    checked={WorkflowSettingType.EmailTemplateId === setting.type}
                    onChange={() =>
                        onChange({
                            ...setting,
                            type: WorkflowSettingType.EmailTemplateId,
                        })
                    }
                    label="Email Template ID"
                />
                <Radio
                    checked={WorkflowSettingType.String === setting.type}
                    onChange={() =>
                        onChange({ ...setting, type: WorkflowSettingType.String })
                    }
                    label="String"
                />
                <Radio
                    checked={WorkflowSettingType.User === setting.type}
                    onChange={() =>
                        onChange({ ...setting, type: WorkflowSettingType.User })
                    }
                    label="User"
                />
                <Radio
                    checked={WorkflowSettingType.Html === setting.type}
                    onChange={() =>
                        onChange({ ...setting, type: WorkflowSettingType.Html })
                    }
                    label="HTML"
                />
                <Radio
                    checked={WorkflowSettingType.Number === setting.type}
                    onChange={() =>
                        onChange({ ...setting, type: WorkflowSettingType.Number })
                    }
                    label="Number"
                />
                <Radio
                    checked={WorkflowSettingType.ClientEmails === setting.type}
                    onChange={() =>
                        onChange({ ...setting, type: WorkflowSettingType.ClientEmails })
                    }
                    label="Client Emails"
                />
            </div>
            <div>
                <p>Scope</p>
                <Radio
                    checked={setting.scope === "tenant"}
                    onChange={() => onChange({ ...setting, scope: "tenant" })}
                    label="Tenant"
                />
                <Radio
                    checked={setting.scope === "user"}
                    onChange={() => onChange({ ...setting, scope: "user" })}
                    label="User"
                />
            </div>
        </div>
    );
};
