import { Radio } from "components/ui/radio";
import { WorkflowStatus } from "generated/graphql";
import React from "react";

interface WorkflowStatusEditProps {
    value: WorkflowStatus;
    onChange: (value: WorkflowStatus) => void;
}

export const WorkflowStatusEdit: React.FC<WorkflowStatusEditProps> = props => {
    const { value, onChange } = props;

    return (
        <div>
            <p>Status</p>
            <Radio
                checked={value === WorkflowStatus.Draft}
                onChange={() => onChange(WorkflowStatus.Draft)}
                label="Draft"
            />
            <Radio
                checked={value === WorkflowStatus.Published}
                onChange={() => onChange(WorkflowStatus.Published)}
                label="Published"
            />
        </div>
    );
};
