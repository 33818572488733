import { QuestionType, TenantId, getEntityField } from "@sp-crm/core";
import { DeleteButton } from "components/ui/action-button";
import { Input } from "components/ui/input";
import { Radio } from "components/ui/radio";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import {
    BridgeEntityResult,
    BridgeFieldResult,
    EntityUpdatedFieldMatcherInput,
    WorkflowEventType,
    WorkflowPayload,
    WorkflowTriggerFieldValueEntityValueTypeInput,
    useGetEntitiesForTenantQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { stableQueryOptions } from "util/requests";
import { replaceItemAtIndex } from "./util";

interface WorkflowTriggerEditProps {
    tenantId: TenantId;
    trigger: WorkflowPayload["triggerInput"];
    onChange: (trigger: WorkflowPayload["triggerInput"]) => void;
}

export const WorkflowTriggerEdit: React.FC<WorkflowTriggerEditProps> = props => {
    const { trigger, onChange, tenantId } = props;
    const selectedEventType = trigger.eventType;
    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === WorkflowEventType.EntityUpdated) {
            onChange({
                eventType: WorkflowEventType.EntityUpdated,
                entityUpdated: {
                    fieldMatchers: [],
                },
            });
        } else if (value === WorkflowEventType.NoteAdded) {
            onChange({
                eventType: WorkflowEventType.NoteAdded,
                noteAdded: {
                    entityFieldMatchers: [],
                },
            });
        } else if (value === WorkflowEventType.EmailOpened) {
            onChange({
                eventType: WorkflowEventType.EmailOpened,
                emailOpened: {
                    entityFieldMatchers: [],
                    activityThresholdDays: 21,
                },
            });
        } else {
            throw new Error(`Unexpected value ${value}`);
        }
    };

    const getEntitiesQuery = useGetEntitiesForTenantQuery(
        { tenantId: tenantId },
        stableQueryOptions(),
    );
    const entityMetadata = getEntitiesQuery.data?.getEntitiesForTenant.find(
        e => e.name === "Client",
    );

    if (!entityMetadata) {
        return <Spinner />;
    }
    return (
        <div className="space-y-4 p-4 rounded-lg bg-gray-100">
            <div>
                <Radio
                    label="Entity Updated"
                    value={WorkflowEventType.EntityUpdated}
                    checked={selectedEventType === WorkflowEventType.EntityUpdated}
                    onChange={handleRadioChange}
                />
                <Radio
                    label="Note Added"
                    value={WorkflowEventType.NoteAdded}
                    checked={selectedEventType === WorkflowEventType.NoteAdded}
                    onChange={handleRadioChange}
                />
                <Radio
                    label="Email Opened"
                    value={WorkflowEventType.EmailOpened}
                    checked={selectedEventType === WorkflowEventType.EmailOpened}
                    onChange={handleRadioChange}
                />
            </div>
            {selectedEventType === WorkflowEventType.EntityUpdated ? (
                <EntityUpdatedTriggerEdit
                    entityMetadata={entityMetadata}
                    configuration={trigger.entityUpdated}
                    onChange={configuration =>
                        onChange({ ...trigger, entityUpdated: configuration })
                    }
                />
            ) : selectedEventType === WorkflowEventType.NoteAdded ? (
                <NoteAddedTriggerEdit
                    entityMetadata={entityMetadata}
                    configuration={trigger.noteAdded}
                    onChange={configuration =>
                        onChange({ ...trigger, noteAdded: configuration })
                    }
                />
            ) : null}
        </div>
    );
};

interface EntityUpdatedTriggerEditProps {
    entityMetadata: BridgeEntityResult;
    configuration: WorkflowPayload["triggerInput"]["entityUpdated"];
    onChange: (entityUpdated: WorkflowPayload["triggerInput"]["entityUpdated"]) => void;
}

const EntityUpdatedTriggerEdit: React.FC<EntityUpdatedTriggerEditProps> = props => {
    const { entityMetadata, configuration, onChange } = props;
    return (
        <div>
            <ul>
                {configuration.fieldMatchers.map((matcher, index) => (
                    <li key={index}>
                        <WorkflowTriggerFieldValueEdit
                            allowPrevious={true}
                            entityMetadata={entityMetadata}
                            matcher={matcher}
                            onChange={matcher =>
                                onChange({
                                    ...configuration,
                                    fieldMatchers: replaceItemAtIndex(
                                        configuration.fieldMatchers,
                                        index,
                                        matcher,
                                    ),
                                })
                            }
                            onDelete={() =>
                                onChange({
                                    ...configuration,
                                    fieldMatchers: configuration.fieldMatchers.filter(
                                        (_, i) => i !== index,
                                    ),
                                })
                            }
                        />
                    </li>
                ))}
            </ul>
            <SecondaryButton
                onClick={e => {
                    e.preventDefault();
                    onChange({
                        ...configuration,
                        fieldMatchers: [
                            ...configuration.fieldMatchers,
                            {
                                fieldName: entityMetadata.fields[0].name,
                                previous: null,
                                current: { specialValue: "$NOT_EMPTY" },
                            },
                        ],
                    });
                }}>
                Add Field
            </SecondaryButton>
        </div>
    );
};

interface NoteAddedTriggerEditProps {
    entityMetadata: BridgeEntityResult;
    configuration: WorkflowPayload["triggerInput"]["noteAdded"];
    onChange: (noteAdded: WorkflowPayload["triggerInput"]["noteAdded"]) => void;
}

const NoteAddedTriggerEdit: React.FC<NoteAddedTriggerEditProps> = props => {
    const { entityMetadata, configuration, onChange } = props;

    return (
        <div>
            <p>
                This will trigger every time a note is added. Add optional criteria on
                client fields below.
            </p>
            <ul>
                {configuration.entityFieldMatchers.map((matcher, index) => (
                    <li key={index} className="pl-4 pb-4">
                        <WorkflowTriggerFieldValueEdit
                            entityMetadata={entityMetadata}
                            allowPrevious={false}
                            matcher={matcher}
                            onChange={matcher =>
                                onChange({
                                    ...configuration,
                                    entityFieldMatchers: replaceItemAtIndex(
                                        configuration.entityFieldMatchers,
                                        index,
                                        {
                                            fieldName: matcher.fieldName,
                                            current: matcher.current,
                                        },
                                    ),
                                })
                            }
                            onDelete={() =>
                                onChange({
                                    ...configuration,
                                    entityFieldMatchers:
                                        configuration.entityFieldMatchers.filter(
                                            (_, i) => i !== index,
                                        ),
                                })
                            }
                        />
                    </li>
                ))}
            </ul>
            <SecondaryButton
                onClick={e => {
                    e.preventDefault();
                    onChange({
                        ...configuration,
                        entityFieldMatchers: [
                            ...configuration.entityFieldMatchers,
                            {
                                fieldName: entityMetadata.fields[0].name,
                                current: { specialValue: "$NOT_EMPTY" },
                            },
                        ],
                    });
                }}>
                Add Field
            </SecondaryButton>
        </div>
    );
};

interface WorkflowTriggerFieldValueEditProps {
    allowPrevious: boolean;
    entityMetadata: BridgeEntityResult;
    matcher: EntityUpdatedFieldMatcherInput;
    onChange: (matcher: EntityUpdatedFieldMatcherInput) => void;
    onDelete: () => void;
}

export const WorkflowTriggerFieldValueEdit: React.FC<
    WorkflowTriggerFieldValueEditProps
> = props => {
    const { matcher: trigger, onChange, entityMetadata, onDelete, allowPrevious } = props;

    const handleFieldChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange({
                fieldName: e.target.value,
                previous: null,
                current: { specialValue: "$NOT_EMPTY" },
            });
        },
        [onChange],
    );

    const eligibleFields = useMemo(() => {
        return entityMetadata.fields.filter(
            field =>
                field.questionType === QuestionType.nary ||
                field.questionType === QuestionType.text ||
                field.questionType === QuestionType.customList ||
                field.questionType === QuestionType.date,
        );
    }, [entityMetadata.fields]);

    const fieldMetadata = useMemo(() => {
        return getEntityField(entityMetadata, trigger.fieldName);
    }, [entityMetadata, trigger.fieldName]);

    return (
        <div className="flex">
            <div className="space-y-2 flex-1">
                <Select
                    label="Client field"
                    value={trigger.fieldName}
                    onChange={handleFieldChange}>
                    {eligibleFields.map(field => (
                        <option key={field.name} value={field.name}>
                            {field.title}
                        </option>
                    ))}
                </Select>
                <div className="flex space-x-8">
                    {allowPrevious ? (
                        <div>
                            <p>Previous Value</p>
                            <FieldValueConditionEdit
                                fieldMetadata={fieldMetadata}
                                allowEmpty={true}
                                condition={trigger.previous}
                                onChange={(
                                    p: WorkflowTriggerFieldValueEntityValueTypeInput,
                                ) => onChange({ ...trigger, previous: p })}
                            />
                        </div>
                    ) : null}
                    <div>
                        <p>Current Value</p>
                        <FieldValueConditionEdit
                            fieldMetadata={fieldMetadata}
                            condition={trigger.current}
                            allowChanged={allowPrevious}
                            onChange={(
                                c: WorkflowTriggerFieldValueEntityValueTypeInput,
                            ) => onChange({ ...trigger, current: c })}
                        />
                    </div>
                </div>
            </div>
            <DeleteButton backgroundColor="bg-gray-200" onClick={onDelete} />
        </div>
    );
};

interface FieldValueConditionEditProps {
    fieldMetadata: BridgeFieldResult;
    allowEmpty?: boolean;
    allowChanged?: boolean;
    condition: WorkflowTriggerFieldValueEntityValueTypeInput;
    onChange: (condition: WorkflowTriggerFieldValueEntityValueTypeInput) => void;
}

const FieldValueConditionEdit: React.FC<FieldValueConditionEditProps> = props => {
    const { condition, onChange, allowEmpty, fieldMetadata, allowChanged } = props;

    const handleRadioChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;

            if (value === "$ANY") {
                onChange(null);
            } else if (value === "$EMPTY") {
                onChange({ specialValue: "$EMPTY" });
            } else if (value === "$NOT_EMPTY") {
                onChange({ specialValue: "$NOT_EMPTY" });
            } else if (value === "$SPECIFIC_VALUES") {
                onChange({ values: [] });
            } else if (value === "$CHANGED") {
                onChange({ specialValue: "$CHANGED" });
            }
        },
        [onChange],
    );

    const naryOptions = useMemo(() => {
        if (
            fieldMetadata.questionType === QuestionType.nary &&
            Array.isArray(fieldMetadata.naryValues)
        ) {
            return fieldMetadata.naryValues.map(o => ({
                value: o.value,
                label: o.label,
            }));
        } else {
            return [];
        }
    }, [fieldMetadata]);

    const allowSpecificValues =
        fieldMetadata?.questionType === QuestionType.nary ||
        fieldMetadata?.questionType === QuestionType.text;

    return (
        <div className="min-w-80">
            <div>
                {allowEmpty ? (
                    <Radio
                        label="Any Value"
                        value="$ANY"
                        checked={!condition}
                        onChange={handleRadioChange}
                    />
                ) : null}
                <Radio
                    label="Empty"
                    value="$EMPTY"
                    checked={condition?.specialValue === "$EMPTY"}
                    onChange={handleRadioChange}
                />
                <Radio
                    label="Not Empty"
                    value="$NOT_EMPTY"
                    checked={condition?.specialValue === "$NOT_EMPTY"}
                    onChange={handleRadioChange}
                />
                {allowChanged ? (
                    <Radio
                        label="Different from Previous"
                        value="$CHANGED"
                        checked={condition?.specialValue === "$CHANGED"}
                        onChange={handleRadioChange}
                    />
                ) : null}
                {allowSpecificValues ? (
                    <Radio
                        label="Specific Values"
                        value="$SPECIFIC_VALUES"
                        checked={Array.isArray(condition?.values)}
                        onChange={handleRadioChange}
                    />
                ) : null}
            </div>
            {Array.isArray(condition?.values) ? (
                <div className="space-y-2">
                    {condition.values.map((value, index) => (
                        <div key={index} className="flex items-center">
                            {naryOptions.length > 0 ? (
                                <Select
                                    value={value}
                                    onChange={e =>
                                        onChange({
                                            ...condition,
                                            values: replaceItemAtIndex(
                                                condition.values,
                                                index,
                                                e.target.value,
                                            ),
                                        })
                                    }>
                                    {naryOptions.map(o => (
                                        <option key={o.value} value={o.value}>
                                            {o.label}
                                        </option>
                                    ))}
                                </Select>
                            ) : (
                                <Input
                                    type="text"
                                    value={value}
                                    onChange={e =>
                                        onChange({
                                            ...condition,
                                            values: replaceItemAtIndex(
                                                condition.values,
                                                index,
                                                e.target.value,
                                            ),
                                        })
                                    }
                                />
                            )}
                            <DeleteButton
                                backgroundColor="bg-gray-200"
                                onClick={() =>
                                    onChange({
                                        ...condition,
                                        values: condition.values.filter(
                                            (_v, i) => i !== index,
                                        ),
                                    })
                                }
                            />
                        </div>
                    ))}
                    <div>
                        <SecondaryButton
                            onClick={e => {
                                e.preventDefault();
                                onChange({
                                    ...condition,
                                    values: [
                                        ...condition.values,
                                        naryOptions.length > 0
                                            ? naryOptions[0].value
                                            : "",
                                    ],
                                });
                            }}>
                            Add Value
                        </SecondaryButton>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
